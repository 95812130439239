@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* Hide scrollbar for Chrome, Safari and Opera */ 
::-webkit-scrollbar { display: none; }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::selection {
  background-color: #ffffff; 
  color: #0f0f10;           
}

p::selection {
  background-color: #ffffff;
  color: #0f0f10;
}

body {
  background-color: #0f0f10;  
  font-family: 'Lato', sans-serif;
}
