body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 50px;
}

.center:before,
.center:after,
.center div {
  background: #fff;
  content: "";
  display: block;
  height: 3px;
  border-radius: 8px;
  margin: 7px ;
  transition: 0.3s;
}


.effect:before {
  transform: translateY(12px) rotate(135deg);
}
.effect:after {
  transform: translateY(-12px) rotate(-135deg);
}
.effect div {
  transform: scale(0);
}
